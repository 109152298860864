











































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, Product } from '@/interfaces'

export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      busy: false,
      viewportWidth: 0,
      chosenProduct: null,
      products: [],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    if (this.user && this.products.length === 0) {
      this.loadProducts()
    }

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    closeModal(updateCheckboxValue = true) {
      this.chosenProduct = null

      this.$emit('closeFeeModal', updateCheckboxValue)
    },
    submit() {
      this.busy = true
      this.$api
        .put(`/requisitionitems/fee/add`, {
          items: this.items,
          data: this.chosenProduct,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          const countItems = apiResponse.data.count
          this.$emit('updateItems', apiResponse.data.items)
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: `Le frais a été appliqué sur ${countItems} lot(s). Vous pouvez ajoutez d'autres frais ou fermer la fenêtre`,
          })
        })
        .finally(() => {
          this.busy = false
          this.closeModal(false)
        })
    },
    loadProducts() {
      this.products = []

      const items = this.items.join(',')

      this.$api
        .get('/products', {
          params: {
            items: items,
            type: 'requisition',
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.products = apiResponse.data
          this.products.forEach((product: Product) => {
            product.name = this.getLabel(product)
          })
        })
    },
    getLabel(product: Product) {
      return (
        product.name +
        ' - P.U ' +
        product.unitPriceExcludingTaxes +
        ' - ' +
        product.tvaRate +
        '% - ' +
        product.structure?.name
      )
    },
  },
})
